import request from '@/utils/request'

// 列表排序
export function sort(data) {
  return request({
    url: '/sort',
    method: 'post',
    data
  })
}
