<template>
  <a-modal
    v-model="isShow"
    centered
    :mask-closable="false"
    :ok-button-props="{ props: { loading: submitting } }"
    title="排序"
    @ok="handleSubmit"
  >
    <a-table
      size="middle"
      :columns="tableColumns"
      :data-source="sortData"
      :pagination="false"
      :scroll="{ y: 350 }"
      row-key="id"
    >
      <span slot="name_title">
        {{ nameTitle }}
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="top(record.id)">置顶</a>
          <a @click="up(record.id)">上移</a>
          <a @click="down(record.id)">下移</a>
        </a-space>
      </span>
    </a-table>
  </a-modal>
</template>

<script>
import { sort } from '@/api/sort'
export default {
  name: 'SortIndex',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    tableName: {
      type: String,
      required: true
    },
    nameTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      submitting: false,
      sortData: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    tableColumns() {
      return [
        {
          slots: { title: 'name_title' },
          dataIndex: 'name'
        },
        {
          title: '操作',
          width: 150,
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  created() {
    this.sortData = this.data
  },
  methods: {
    top(id) {
      const objIndex = this.$lodash.findIndex(this.sortData, { id: id })
      if (objIndex === 0) {
        this.$warning({
          title: '已置顶',
          content: ''
        })
        return
      }
      const obj = this.sortData[objIndex]
      const newData = [obj]
      for (const item of this.sortData) {
        if (item.id === id) {
          continue
        }
        newData.push(item)
      }
      this.sortData = newData
    },

    up(id) {
      const objIndex = this.$lodash.findIndex(this.sortData, { id: id })
      if (objIndex === 0) {
        this.$warning({
          title: '已置顶无法上移',
          content: ''
        })
        return
      }

      const obj = this.sortData[objIndex]
      const lastObj = this.sortData[objIndex - 1]
      this.$set(this.sortData, objIndex - 1, obj)
      this.$set(this.sortData, objIndex, lastObj)
    },

    down(id) {
      const objIndex = this.$lodash.findIndex(this.sortData, { id: id })
      if (objIndex === this.sortData.length - 1) {
        this.$warning({
          title: '已置底无法下移',
          content: ''
        })
        return
      }
      const obj = this.sortData[objIndex]
      const nextObj = this.sortData[objIndex + 1]
      this.$set(this.sortData, objIndex + 1, obj)
      this.$set(this.sortData, objIndex, nextObj)
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (!this.sortData || this.sortData.length === 0) {
        this.$warning({
          title: '暂无数据，无法排序',
          content: ''
        })
        return
      }

      this.submitting = true
      const ids = this.$lodash.map(this.sortData, 'id')
      sort({ ids: ids.reverse(), table_name: this.tableName }).then((res) => {
        if (res.code === 0) {
          // 关闭模态框
          this.isShow = false
          // 告知父组件已完成
          this.$emit('completed')
        }
        this.submitting = false
      })
    }
  }
}
</script>
